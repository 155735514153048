import api from "../servicios/apiCliente";
import { ref } from "vue";
import { useRouter } from "vue-router"; // Import useRouter

export const useRedirectHome = () => {

  const isLoading = ref(false);
  const router = useRouter(); // Access router instance

  const validateAuth = async () => {

    try {
      isLoading.value = true;
      const resp = await api.Cliente.Cliente.HomeApi.Sesion();
      if (resp.data) {
        router.push({ name: "cliente-cliente-home" }); // Use router.push
      } else {
        window.location.href = "https://hircasa.com.mx/";
      }
    } catch (error) {
      console.log("Error al verificar la autenticación:", error);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    validateAuth,
  };
};
